import React, { useEffect } from "react";
import { getQueryParam } from "../../services/urlParamService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createResource, fetchCollection, queryKeys } from "../../services/reactQuery/reactQueryService";
import { CheckoutOrderProvider, useCheckoutOrder } from "../../provider/CheckoutOrder/CheckoutOrderProvider";
import _head from "lodash/head";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import { Elements } from "@stripe/react-stripe-js";
import Layout from "../../containers/Layouts/Layout";
import { Helmet } from "react-helmet-async";
import Checkout from "../../containers/HigherOrderContainers/Checkout";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../config/_enviroment";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material";
import { PaymentTransaction } from "../../types/Entities/PaymentTransaction";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY as string);

export default function StripeCheckout() {
  const location = useLocation();
  const orderId = getQueryParam(location.search, "orderId");

  const navigate = useNavigate();

  if (!orderId) {
    navigate("/servicewelt");
  }

  return (
    <>
      {orderId && (
        <CheckoutOrderProvider orderId={orderId}>
          <StripeCheckoutOrderDataProvider orderIdentifier={orderId} />
        </CheckoutOrderProvider>
      )}
    </>
  );
}

function StripeCheckoutOrderDataProvider({ orderIdentifier }: { orderIdentifier: string }) {
  const { checkoutOrder, isLoading: isLoadingCheckoutOrder, create } = useCheckoutOrder();

  const paymentTransactionApiUri = "/payment_transactions";
  const paymentTransactionFilter = {
    "order.orderIdentifier": orderIdentifier,
  };
  const { data: existingTransactions, isLoading: isLoadingTransaction } = useQuery(
    queryKeys.collection(paymentTransactionApiUri, paymentTransactionFilter),
    () => fetchCollection(paymentTransactionApiUri, paymentTransactionFilter),
    { enabled: !!(checkoutOrder && !isLoadingCheckoutOrder) }
  );
  const currentTransaction = existingTransactions?.length > 0 ? existingTransactions[0] : null;
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoadingCheckoutOrder && !checkoutOrder) {
      create({ orderIdentifier: orderIdentifier }).then(() => {
        queryClient.invalidateQueries(queryKeys.collection("/checkout_orders", { orderIdentifier: orderIdentifier }));
      });
    }
  }, [isLoadingCheckoutOrder]);

  if (isLoadingCheckoutOrder || isLoadingTransaction || !checkoutOrder) {
    return <LegalbirdLoader />;
  }

  return <StripeCheckoutInner orderIdentifier={orderIdentifier} currentTransaction={currentTransaction} checkoutOrder={checkoutOrder} />;
}

function StripeCheckoutInner({
  orderIdentifier,
  currentTransaction,
  checkoutOrder,
}: {
  orderIdentifier: string;
  currentTransaction: PaymentTransaction;
  checkoutOrder: {
    id: string;
    product: { checkoutItem: { orderables: { id: string }[] } };
  };
}) {
  const theme: Theme = useTheme();
  const paymentTransactionApiUri = "/payment_transactions";
  const paymentTransactionFilter = {
    "order.orderIdentifier": orderIdentifier,
  };
  const createMutation = useMutation(createResource);
  const queryClient = useQueryClient();

  const createTransaction = async () => {
    const orderable = _head(checkoutOrder.product.checkoutItem.orderables);
    if (!orderable) {
      throw new Error("No orderable found");
    }
    const data = {
      order: "/checkout_orders/" + checkoutOrder.id,
      options: {
        order: {
          orderables: ["/orderables/" + orderable.id],
        },
      },
    };

    await createMutation.mutateAsync(
      { uri: paymentTransactionApiUri, data: data },
      {
        onSuccess: () => queryClient.invalidateQueries(queryKeys.collection(paymentTransactionApiUri, paymentTransactionFilter)),
      }
    );
  };

  useEffect(() => {
    if (!currentTransaction) {
      createTransaction();
    }
  }, []);

  if (!stripePromise || !currentTransaction) {
    return <LegalbirdLoader />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: currentTransaction.token,
        appearance: { variables: { colorPrimary: theme.palette.primary.main } },
      }}
    >
      <Layout>
        <Helmet>
          <title>Checkout | Legalbird</title>
        </Helmet>
        <Checkout transaction={currentTransaction} />
      </Layout>
    </Elements>
  );
}
