/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { useTheme } from "@mui/material/styles";
import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const Colors = () => {
  const theme = useTheme();

  const paletteEntries = Object.entries(theme.palette).filter(
    ([, value]) => typeof value === "object" && value !== null && ("main" in value || "light" in value || "dark" in value)
  );

  const colorProps = ["main", "light", "dark"] as const;

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Colors
      </Typography>
      <Grid container spacing={2}>
        {paletteEntries.map(([colorKey, colorValue]) => {
          return (
            <Grid item xs={12} key={colorKey}>
              <Typography variant="h5" gutterBottom>
                {colorKey}
              </Typography>
              <Grid container spacing={2}>
                {colorProps.map((prop) => {
                  const colorHex = colorValue[prop];
                  if (!colorHex) return null;

                  return (
                    <Grid item xs={12} sm={4} md={4} key={prop}>
                      <Box
                        sx={{
                          bgcolor: colorHex,
                          color: theme.palette.getContrastText(colorHex),
                          p: 2,
                          borderRadius: 1,
                        }}
                      >
                        <Typography variant="body1">{prop}</Typography>
                        <Typography variant="body2">{colorHex}</Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Colors;
