import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkEmail, register } from "../../../store/login/actions";
import { getLpByLocation } from "../../../services/landingPagesList";
import trafficCheckinStyle from "./trafficCheckinStyle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FormProvider } from "../../../provider/Form/FormProvider";
import TrafficCheckinForm from "./TrafficCheckinForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createResource, queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import { APPLICATION_STATUS_AUTHORITY_NOTIFICATION } from "../../../services/traffic/applicationStatus";
import {Box} from "@mui/material";

type TrafficCheckinPartnerInsurerProps = {
  idPrefix: string;
  color?: "default" | "grey";
  checkinHeading?: string;
  target?: string;
  partner: string;
  accusation: AccusationProps;
};

type AccusationProps = {
  name: string;
  detail: {
    name: string;
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  };
};

const defaultAccusation = {
  name: "accusation",
  detail: {
    name: "accusation",
    label: "Vorwurf auswählen",
    options: [
      { value: "speeding", label: "Geschwindigkeitsverstoß" },
      { value: "red_light", label: "Rotlichtverstoß" },
      { value: "distance", label: "Abstandsverstoß" },
      { value: "other", label: "Handy / Drogen / Parken / Sonstiges" },
    ],
  },
};

export default function TrafficCheckinPartnerInsurer({
  accusation = defaultAccusation,
  partner,
  target = "/servicewelt",
  idPrefix,
  checkinHeading = "Wir geben Ihnen eine kostenlose Ersteinschätzung! Was wird Ihnen vorgeworfen?",
  color = "default",
}: TrafficCheckinPartnerInsurerProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    accusationDetail: "",
    caseReferenceNumber: "",
    email: "",
    telephone: "",
  };

  const [emailErrorText, setEmailErrorText] = useState("");
  const queryClient = useQueryClient();
  const createMutation = useMutation(createResource);
  const updateMutation = useMutation(updateResource);

  const handleSubmit = async ({ values }: { values: Record<string, any> }) => {
    setIsLoading(true);
    const emailCheck = await checkEmail(values.email.toLowerCase());
    if (emailCheck.hasError) {
      setIsLoading(false);
      setEmailErrorText(emailCheck.errorText);
      return;
    }

    const landingPage = getLpByLocation(location.pathname) || { keyword: location.pathname };
    const accusationBase = accusation.name !== "accusation" ? accusation.name : values.accusationDetail;
    const data = {
      email: values.email.toLowerCase(),
      telephone: values.telephone,
      gclid: localStorage.getItem("gclid"),
      prelead: {
        initialProduct: "traffic",
        accusation: accusationBase,
        accusationDetail: {
          [accusation.detail.name]: values.accusationDetail,
        },
        landingPage: landingPage.keyword,
        partner: partner,
      },
    };

    const { from } = location.state || { from: { pathname: target } };
    await register(data);
    const product = await createMutation.mutateAsync({
      uri: "/traffic",
      data: {
        acquisitionPartner: partner,
        affiliateCode: localStorage.getItem("gclid"),
        accusation: accusationBase,
        applicationStatus: APPLICATION_STATUS_AUTHORITY_NOTIFICATION,
        landingPage: landingPage.keyword,
      },
    });
    await updateMutation.mutateAsync({
      uri: "/customer_insurances",
      id: product.insurance.id,
      data: { caseReferenceNumber: values.caseReferenceNumber },
    });
    await queryClient.invalidateQueries(queryKeys.item("/traffic", product.id));
    await queryClient.invalidateQueries(queryKeys.collection("/customers"));
    navigate(from.pathname);
  };

  return (
    <Paper elevation={4} sx={[trafficCheckinStyle.formPaper, trafficCheckinStyle[color]]}>
      <Box id={idPrefix + "-checkin"} sx={trafficCheckinStyle.scrollToAnchor} />
      <Typography variant={"h4"} sx={trafficCheckinStyle.heading}>
        {checkinHeading}
      </Typography>
      <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
        <TrafficCheckinForm
          partnerInsurer={partner}
          accusation={accusation}
          isLoading={isLoading}
          emailErrorText={emailErrorText}
          setEmailErrorText={setEmailErrorText}
        />
      </FormProvider>
    </Paper>
  );
}
