/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { Helmet } from "react-helmet-async";
import { gaSet } from "../../../../../services/cookieService";
import { LpMetaProps } from "../../../../../types/LpMetaProps";

const LpDivorcePetitionMeta = ({ keyword }: LpMetaProps) => {
  const title = keyword + " bei Legalbird | schnell & professionell!";
  gaSet({ title: title });

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="Reichen Sie Ihren Scheidungsantrag online ein - schnell, einfach und nirgendwo günstiger!" />
      <meta name="keywords" content="Scheidungsantrag, Scheidung, einreichen" />
      <meta name="robots" content="noindex,nofollow" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content="Reichen Sie Ihren Scheidungsantrag online ein - schnell, einfach und nirgendwo günstiger!" />
    </Helmet>
  );
};

export default LpDivorcePetitionMeta;
