/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Layout from "../../../../Layouts/Layout";
import LpCalculateSettlementMeta from "./LpCalculateSettlementMeta";
import { getLastPathSplit } from "../../../../../services/redirect";
import { landingPagesList } from "../../../../../services/landingPagesList";
import TrustSection from "../../../../../components/Sections/Generic/TrustSection/TrustSection";
import NoWinNoFeeSection from "../../../../../components/Sections/Settlement/NoWinNoFeeSection/NoWinNoFeeSection";
import LandingPageTeaser from "../../../../../components/Teaser/LandingPageTeaser";
import PartnersSection from "../../../../../components/Sections/Generic/PartnersSection/PartnersSection";
import CalculateSettlementCheckin from "../../../../../components/Checkin/CalculateSettlementCheckin/CalculateSettlementCheckin";
import HowItWorksSection from "../../../../../components/Sections/Settlement/HowItWorksSection/HowItWorksSection";
import SettlementTimeSection from "../../../../../components/Sections/Settlement/SettlementTimeSection/SettlementTimeSection";
import TeamSection from "../../../../../components/Sections/Generic/TeamSection/TeamSection";
import { settlementTeaserCards } from "../../../../../services/landingPageService";
import LinkedCardBox from "../../../../../components/LinkedCardBox/LinkedCardBox";
import GreyBackgroundWrapper from "../../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import TextContainer from "../../../../../components/Container/TextContainer";
import { useLocation } from "react-router-dom";
import { useCustomer } from "../../../../../provider/Customer/CustomerProvider";

const LpCalculateSettlement = () => {
  const location = useLocation();
  const lpKey = getLastPathSplit(location.pathname);
  const keyword = lpKey && landingPagesList[lpKey] ? landingPagesList[lpKey].keyword : "Abfindung bei Kündigung";
  const headline = (lpKey && landingPagesList[lpKey]?.headline) ?? "Abfindung bei Kündigung";
  const { isLoggedIn } = useCustomer();

  return (
    <Layout minimal={!isLoggedIn} clickableLogo={isLoggedIn} backgroundType={"landingpage"}>
      <LpCalculateSettlementMeta keyword={keyword} />
      <LandingPageTeaser
        headline={headline}
        subheadline={"Kündigung erhalten? Jetzt ohne Kostenrisiko Abfindung sichern!"}
        hasTeaserButton={true}
        teaserButtonText={"Kostenlos berechnen & prüfen"}
        teaserButtonLink={isLoggedIn ? "/servicewelt" : "#top-checkin"}
      >
        <LinkedCardBox linkedCards={settlementTeaserCards} />
      </LandingPageTeaser>
      <GreyBackgroundWrapper>
        {!isLoggedIn && (
          <TextContainer>
            <CalculateSettlementCheckin idPrefix={"top"} />
          </TextContainer>
        )}
        <HowItWorksSection />
      </GreyBackgroundWrapper>
      <NoWinNoFeeSection />
      <GreyBackgroundWrapper>
        <SettlementTimeSection />
        <TeamSection
          headline={"Sie möchten professionell gegen Ihre Kündigung angehen?"}
          subHeadline={"Wir kümmern uns persönlich um Sie"}
          showFamilyLawTitle={false}
        />
      </GreyBackgroundWrapper>
      <TrustSection />
      <PartnersSection />
    </Layout>
  );
};

export default LpCalculateSettlement;
