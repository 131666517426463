const iconsOverviewPageStyle = {
  container: {
    paddingTop: "10px",
  },
  icon: {
    width: "100px",
    height: "100px",
  },
  grid: {
    textAlign: "center",
  },
  typography: {
    wordWrap: "break-word",
    cursor: "pointer"
  },
};

export default iconsOverviewPageStyle;
